$(function () {
	$('[data-toggle="popover"]').each( function( index, element ){
		var popoverTitle = $(element).attr('data-title');
		$(element).popover({
			container: 'body',
			placement: 'bottom',
			html: true,
			title : popoverTitle + '<span id="close" class="close">&times;</span>'
		}).on('shown.bs.popover', function () {
			$('html').on('click', function(){
				$(element).popover('hide');
				$('html').off('click');
			});
		});
	});
});