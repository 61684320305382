const resetBodyStyle = function() {
	document.body.style.position = '';
	document.body.style.top = '';
	document.body.style.width = '';	
}

const overscrollBehaviorNone = function(event){
	event.preventDefault();
}

const removeEventListenerBody = function() {
	document.removeEventListener('touchmove', overscrollBehaviorNone);
}

const cropImage = function (e) {
	const zoomRatio = 0.1;
	const defaultViewCanvasLength = 500;
	let viewCanvasLength = defaultViewCanvasLength;
	let viewCanvasWidth = defaultViewCanvasLength;
	let zoomRangeValue = 0;
	let cropper = null;
	let targetId = e.target.id;
	let aspectNumerator = Number(document.getElementById(targetId + "_aspect_numerator").value);
	let aspectDenominator = Number(document.getElementById(targetId + "_aspect_denominator").value);
	let files = e.target.files;
	let scale = 1;
	let leftOffset = 5;

	document.body.style.position = 'fixed';
	document.body.style.top = `-${window.scrollY}px`;
	document.body.style.width = '100%';

	document.addEventListener('touchmove', overscrollBehaviorNone, { passive: false });
 
	document.getElementById("modal_area").style.display = 'block';
	const inputCropRange = document.getElementById("crop_range");
	inputCropRange.value = zoomRangeValue;

	const canvasWrapper = document.querySelector("#modal_area .canvas_wrapper");
	while(canvasWrapper.lastChild){
		canvasWrapper.removeChild(canvasWrapper.lastChild);
	}
	const sourceCanvas = document.createElement('canvas');
	sourceCanvas.setAttribute("id", "source_canvas");
	sourceCanvas.setAttribute("width", "1");
	sourceCanvas.setAttribute("height", "1");
	canvasWrapper.append(sourceCanvas);

	let shortScaleWidth = 1; 
	const windowWidth = window.innerWidth - 40;
	if (defaultViewCanvasLength > windowWidth) {
		if (aspectDenominator > windowWidth) {
			shortScaleWidth = windowWidth / defaultViewCanvasLength;
		}
		viewCanvasWidth = windowWidth;
		viewCanvasLength = windowWidth;
		leftOffset = 0;
	}

	if (files.length == 0) {
		return;
	}
	let file = files[0];
	let image = new Image();
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function (e) {
		image.src = e.target.result;
		image.onload = function () {

			let imageWidth = image.width;
			let imageHeight = image.height;

			let ratioWidth = 1;
			let canvasLength = imageWidth;
			if (imageWidth < imageHeight) {
				ratioWidth = image.height / image.width;
				canvasLength = imageHeight;
			}
			if (viewCanvasLength > canvasLength) {
				canvasLength = viewCanvasLength;
			}

			let canvasScaleLength = canvasLength * scale;

			let drawWidth = imageWidth * scale;
			let drawHeight = imageHeight * scale;
			let drawX = ( canvasScaleLength - drawWidth ) / 2;
			let drawY = ( canvasScaleLength - drawHeight ) / 2;

			let canvas = document.getElementById("source_canvas");
			canvas.width = canvasScaleLength;
			canvas.height = canvasScaleLength;

			let ctx = canvas.getContext("2d");
			ctx.drawImage(image, 0, 0, imageWidth, imageHeight, drawX, drawY, drawWidth, drawHeight);

			let scaleCavasWidth = aspectDenominator * ratioWidth;
			if (canvasScaleLength > viewCanvasLength){
				if (scaleCavasWidth > imageWidth) {
					scaleCavasWidth = imageWidth * ratioWidth;
				}			
			} else {
				if (scaleCavasWidth > imageWidth) {

				}
				if (scaleCavasWidth < viewCanvasLength) {
					scaleCavasWidth = viewCanvasLength;
				}
			}
			scaleCavasWidth = scaleCavasWidth * shortScaleWidth;

			let canvasData = {
				top: ( ( viewCanvasLength - scaleCavasWidth ) / 2 ) + 3,
				left: ( ( viewCanvasWidth - scaleCavasWidth ) / 2 ) + leftOffset,
				width: scaleCavasWidth
			};

			let cropBoxData = {
				top: ( viewCanvasLength - aspectNumerator * shortScaleWidth ) / 2 + 3,
				left: ( ( viewCanvasWidth - aspectDenominator * shortScaleWidth ) / 2 ) + leftOffset,
				width: aspectDenominator * shortScaleWidth,
				height: aspectNumerator * shortScaleWidth
			};

			if (cropper != null) {
				cropper.destroy();
			}

			cropper = new Cropper(canvas,
				{
					minContainerWidth: 300,
					minContainerHeight: 300,
					dragMode: 'move',
					cropBoxMovable: false,
					cropBoxResizable: false,
					zoomOnWheel: false,
					center: false,
					zoomOnTouch: false,
					ready() {
						this.cropper.setCanvasData(canvasData);
						this.cropper.setCropBoxData(cropBoxData);
					},
					crop: function (event) {
						document.getElementById(targetId + "_image_x").value = ( event.detail.x - drawX ) / scale;
						document.getElementById(targetId + "_image_y").value = ( event.detail.y - drawY ) / scale;
						document.getElementById(targetId + "_image_w").value = event.detail.width / scale;
						document.getElementById(targetId + "_image_h").value = event.detail.height / scale;
					}
				}
			);

			$('#crop_button').off();
			$('#crop_button').on('click', function(){
				cropper.destroy();
				let cropped_canvas = document.getElementById(targetId + "_cropped_canvas");
				let croppedCtx = cropped_canvas.getContext("2d");
				cropped_canvas.width = aspectDenominator;
				cropped_canvas.height = aspectNumerator;

				let imageX = document.getElementById(targetId + "_image_x").value;
				let imageY = document.getElementById(targetId + "_image_y").value;
				let imageW = document.getElementById(targetId + "_image_w").value;
				let imageH = document.getElementById(targetId + "_image_h").value;
				croppedCtx.drawImage(image, imageX, imageY, imageW, imageH, 0, 0, cropped_canvas.width, cropped_canvas.height);

				let imageThumbnail = document.getElementById(targetId + "_thumbnail");
				if(imageThumbnail !== null){
					imageThumbnail.style.display = 'none';
				}
				document.getElementById(targetId + "_cropped_canvas").style.display = '';
				document.getElementById("modal_area").style.display = 'none';
				resetBodyStyle();
				removeEventListenerBody();
			});

			$('#crop_range').off();
			$('#crop_range').on('input', function(){
				let inputCropRangeValue = Number(this.value);
				let cropperZoomRatio = zoomRatio * (inputCropRangeValue - zoomRangeValue);
				cropper.zoom(cropperZoomRatio);
				zoomRangeValue = inputCropRangeValue;
			});
		}
		image.onerror = function () {
			alert("選択したファイルを読み込むことができませんでした。再度選択して下さい。");
			let inputFile = document.getElementById(targetId);
			inputFile.value = "";
			document.getElementById("modal_area").style.display = 'none';
			resetBodyStyle();
			removeEventListenerBody();
		}
	}
}

$(function(){

	const salonMain01ImgUploader = document.getElementById('salon_main01_img');
	const salonSub01ImgUploader = document.getElementById('salon_sub01_img');
	const salonSub02ImgUploader = document.getElementById('salon_sub02_img');
	const storeTenpoImgUploader = document.getElementById('store_tenpo_img');
	const courseCourseImgUploader = document.getElementById('course_course_img');
	if(salonMain01ImgUploader !== null){
		salonMain01ImgUploader.addEventListener('change', cropImage);
	}
	if(salonSub01ImgUploader !== null){
		salonSub01ImgUploader.addEventListener('change', cropImage);
	}
	if(salonSub02ImgUploader !== null){
		salonSub02ImgUploader.addEventListener('change', cropImage);
	}
	if(storeTenpoImgUploader !== null){
		storeTenpoImgUploader.addEventListener('change', cropImage);
	}
	if(courseCourseImgUploader !== null){
		courseCourseImgUploader.addEventListener('change', cropImage);
	}
	
});