$(function(){
	$('input[type="text"]').focus(function(){
		set_add_word_count_box($(this));
	});
	$('textarea').focus(function(){
		set_add_word_count_box($(this));
	});
	
	$('input[type="text"]').keyup(function(){
		show_word_count_text($(this).attr("id"), $(this).val());
	});

	$('textarea').keyup(function(){
		show_word_count_text($(this).attr("id"), $(this).val());
	});
});

function get_non_breaking_text_length(input_value){
	non_breaking_text_length = input_value.replace(/\n/g, "").length;
	return non_breaking_text_length + "文字";
}
function get_show_display_id(input_id){
	return "#" + input_id + "_cnt";
}
function show_word_count_text(input_id, input_value){
	word_count = get_non_breaking_text_length(input_value);
	show_id = get_show_display_id(input_id);
	$(show_id).text(word_count);
}
function add_word_count_box(input_id, show_id, add_id){
	str = "<span id='"+input_id+"_cnt' class='cnts'></span>";
	if($(show_id).length == 0){ 
		add_id.after(str);
	}
}
function set_add_word_count_box(self_element){
	input_id = self_element.attr("id");
	show_id = get_show_display_id(input_id);
	add_word_count_box(input_id, show_id, self_element);
}